<template>
  <div>
    <teleport selector="#appButton">
      <b-button
        icon-left="database-cog"
        class="px-6 add-new-btn btn-primary-light top-btn"
        @click="goToData"
      >
        Table records
      </b-button>
      <b-button
        class="px-6 add-new-btn btn-primary-light top-btn"
        icon-left="plus"
        @click="openColumnModal(false)"
      >
        New column
      </b-button>
    </teleport>

    <div class="mx-4 mt-4">
      <b-table
        :data="table.columns"
        class="columns-table tab-table-layout"
        draggable
        @dragstart="rowDragStart"
        @drop="drop"
        @dragover="dragover"
        @click="handleColumnTableClick"
      >
        <b-table-column
          v-slot="{ row }"
          label="Name"
        >
          {{ row.column_label }}
        </b-table-column>
        <b-table-column
          v-slot="{ row }"
          label="Type"
        >
          {{ row.column_type }}
        </b-table-column>
        <b-table-column
          v-slot="{ row, index }"
          label=""
          cell-class="has-text-right"
        >
          <b-button
            v-if="row.column_name !== 'id' && !isDefaultDateTypeColumn(row) && (isAdmin || !(!isAdmin && row.column_type === 'PASSWORD'))"
            size="is-small"
            class="app-dropdown-btn"
            @click.stop="toggleColumnActionDropdown(row.column_name)"
          >
            <b-icon
              icon="dots-horizontal"
              size="is-size-6"
              class="app-dropdown-icon"
            />
          </b-button>
          <b-dropdown
            :ref="`columnActionDropdown${row.column_name}`"
            aria-role="list"
            position="is-bottom-left"
            class="app-action-dropdown"
            append-to-body
          >
            <b-dropdown-item
              aria-role="listitem"
              class="is-flex is-align-items-center"
              @click="openColumnModal(true, index)"
            >
              <b-icon
                icon="pencil-outline"
                type="is-black"
                custom-size="mdi-22px"
                class="mr-2"
              />
              Edit
            </b-dropdown-item>
            <b-dropdown-item
              aria-role="listitem"
              custom
              class="is-flex is-align-items-center is-justify-content-center"
            >
              <b-button
                icon-left="delete-outline"
                size="is-small"
                type="is-danger is-light"
                class="rounded-8 w-full"
                @click.stop="confirmColumnDeleting(row.column_name, index)"
              >
                Delete
              </b-button>
            </b-dropdown-item>
          </b-dropdown>
        </b-table-column>
      </b-table>
    </div>
    <!-- column modal -->
    <BaseModal
      v-model="isColumnModalActive"
      :has-modal-card="true"
      :trap-focus="true"
      :destroy-on-hide="false"
      aria-role="dialog"
      :aria-label="isColumnEdited ? 'Edit Column' : 'Add Column'"
      aria-modal
    >
      <CardPopup
        :title="isColumnEdited ? 'Edit Column' : 'Add Column'"
        class="w-800"
        @hide="isColumnModalActive = false"
      >
        <template #body>
          <div class="card-content">
            <b-field
              label="Name"
              :type="selectedColumn.column_label ? '' :'is-danger'"
              :message="selectedColumn.column_label ? '' : 'Column name is required'"
            >
              <b-input v-model="selectedColumn.column_label" />
            </b-field>
            <b-field
              label="Type"
              :type="selectedColumn.column_type ? '' :'is-danger'"
              :message="selectedColumn.column_type ? '' : 'Column type is required'"
            >
              <b-select
                v-model="selectedColumn.column_type"
                type="text"
                expanded
              >
                <option
                  v-for="(type, typeIdx) in options.types"
                  :key="typeIdx"
                >
                  {{ type }}
                </option>
              </b-select>
            </b-field>
          </div>
        </template>
        <template #footer>
          <div class="is-flex is-justify-content-space-between w-full">
            <b-button
              class="px-6 rounded-8 btn-primary-light"
              @click="isColumnModalActive = false"
            >
              Cancel
            </b-button>
            <b-button
              type="is-primary"
              class="px-6 rounded-8"
              @click="handleColumnUpdate()"
            >
              {{ isColumnEdited ? 'Update' : 'Add' }}
            </b-button>
          </div>
        </template>
      </CardPopup>
    </BaseModal>
  </div>
</template>

<script >
import { computed, defineAsyncComponent, onMounted, reactive, ref, getCurrentInstance } from '@vue/composition-api';
import { useBuefy } from '@/hooks/buefy';
import { useRoute, useRouter } from '@/hooks/vueRouter';
import { useSession } from '@/hooks/vueSession';
import { createColumnService, deleteColumnService, updateColumnService, updateColumnPositionService } from '@/services/database-service/columnRequests';
import { getColumnsService } from '@/services/database-service/columnRequests';
import { reactiveResetter } from '@/hooks/utils';
import { cloneDeep } from 'lodash';
import { delay } from '@/helpers/util';
import { useLocalDatabase } from '@/modules/builder/components/module-sidebar/action/local-database/localDatabase';

//-- child components --//
const BaseModal = defineAsyncComponent(() => import('@/modules/core/components/generics/base-modal/BaseModal.vue'));
const CardPopup = defineAsyncComponent(() => import('@/modules/core/components/generics/base-modal/CardPopup.vue'));

// @VUE3: do not use this function when migrating to vue 3
const __sfc_main = {};
__sfc_main.setup = (__props, __ctx) => {
  const vm = getCurrentInstance();

  //-- compose hooks --//
  const route = useRoute();
  const router = useRouter();
  const buefy = useBuefy();
  const session = useSession();

  //-- table logic --//
  const {
    isColumnPartOf,
    isDefaultDateTypeColumn
  } = useLocalDatabase();
  const tableName = ref('');
  /** @type {import('./types/structure').ITable} */
  const table = reactive({
    name: '',
    columns: []
  });
  const isTableFetchDone = ref(false);
  const fetchTableStructure = async () => {
    try {
      const response = await getColumnsService(route.params.databaseId, route.params.tableId);
      tableName.value = route.params.tableId;
      table.columns = response.data.data;
      table.columns.sort((a, b) => a.column_position - b.column_position);
      isTableFetchDone.value = true;
    } catch (err) {
      console.error(err);
    }
  };
  onMounted(() => {
    fetchTableStructure();
  });

  //-- column logic --//
  const isColumnModalActive = ref(false);
  const isColumnEdited = ref(false);
  const options = reactive({
    types: ['DATE', 'DATETIME', 'DECIMAL NUMBER / FLOAT', 'PASSWORD', 'TEXT', 'UNIQUE', 'VARCHAR(255)', 'WHOLE NUMBER / INTEGER'],
    defaultValues: {
      datetime: ['CURRENT TIME'],
      date: ['CURRENT DATE'],
      unique: ['UNIQUE VALUE']
    }
  });
  let [selectedColumn, resetCol] = reactiveResetter({
    column_name: '',
    column_label: '',
    column_type: ''
  });
  /** @type {number | null} */
  let selectedColumnIndex = null;
  const resetSelectedColumn = () => {
    resetCol();
    selectedColumnIndex = null;
  };
  const handleColumnTableClick = tableRow => {
    const colIndex = table.columns.findIndex(col => col.column_name === tableRow.column_name);
    const column = table.columns.find(col => col.column_name === tableRow.column_name);
    if (!isColumnPartOf(tableRow.column_name) && !(!isAdmin.value && column.column_type === 'PASSWORD')) {
      openColumnModal(true, colIndex);
    }
  };
  /**
   * @param {boolean} isColEdited
   * @param {number} [selectedColIndex]
   */
  const openColumnModal = (isColEdited, selectedColIndex) => {
    isColumnEdited.value = isColEdited;
    if (isColEdited) {
      const selectedCol = table.columns[selectedColIndex];
      selectedColumn.column_name = selectedCol.column_name;
      selectedColumn.column_label = selectedCol.column_label;
      selectedColumn.column_type = selectedCol.column_type;
      selectedColumnIndex = selectedColIndex;
    } else {
      resetSelectedColumn();
    }
    isColumnModalActive.value = true;
  };
  const isColumnNameAlreadyExists = () => {
    const isColumnAlreadyExists = table.columns.find(v => v.column_label.toLowerCase() === selectedColumn.column_label.trim().toLowerCase());
    return isColumnAlreadyExists && isColumnAlreadyExists.column_name !== selectedColumn.column_name;
  };
  const isAdmin = computed(() => {
    return session.get('role') === 'admin';
  });
  const isSelectedColumnValid = computed(() => !Object.values(selectedColumn).some(val => !val));
  const handleColumnUpdate = async () => {
    try {
      if (isColumnEdited.value) {
        if (isSelectedColumnValid.value) {
          await updateColumn();
        }
      } else {
        await addColumn();
      }
      isColumnModalActive.value = false;
      resetSelectedColumn();
    } catch (err) {
      console.error(err);
    }
  };
  const addColumn = async () => {
    try {
      selectedColumn.column_label = selectedColumn.column_label.trim();
      if (isColumnNameAlreadyExists()) {
        buefy.toast.open({
          duration: 5000,
          message: `Column ${selectedColumn.column_label} already taken.`,
          position: 'is-top',
          type: 'is-danger'
        });
        return;
      }
      const newColumnRes = await createColumnService(route.params.databaseId, route.params.tableId, {
        columns: [{
          ...selectedColumn,
          default_value: 'null'
        }]
      });
      const newColumn = {
        ...selectedColumn,
        column_name: newColumnRes.data.data.column_id,
        default_value: 'null'
      };
      table.columns.push(newColumn);
      buefy.toast.open('Column added!');
      // reload the table structure
      await fetchTableStructure();
    } catch (err) {
      console.error(err);
      buefy.snackbar.open({
        message: 'Failed to add column',
        type: 'is-danger',
        position: 'is-top',
        indefinite: false
      });
    }
  };
  const updateColumn = async () => {
    try {
      let hasId = false;
      let hasDuplicateId = false;
      table.columns.forEach(column => {
        if (hasId && column?.column_name?.toLowerCase() === 'id') {
          hasDuplicateId = true;
        }
        if (column?.column_name?.toLowerCase() === 'id') {
          hasId = true;
        }
      });
      if (!hasId) {
        buefy.snackbar.open({
          message: 'Id column is missing',
          type: 'is-danger',
          position: 'is-top',
          indefinite: true
        });
      } else if (hasDuplicateId) {
        buefy.snackbar.open({
          message: 'Can not have more than one ID columns',
          type: 'is-danger',
          position: 'is-top',
          indefinite: true
        });
      } else {
        let oldColumn = table.columns[selectedColumnIndex];
        if (isColumnNameAlreadyExists()) {
          buefy.toast.open({
            duration: 5000,
            message: `Column ${selectedColumn.column_label} already taken.`,
            position: 'is-top',
            type: 'is-danger'
          });
          return;
        }
        let requestPayload = {
          columns: [{
            old_name: oldColumn.column_name,
            new_name: selectedColumn.column_name,
            column_label: selectedColumn.column_label,
            column_type: selectedColumn.column_type,
            default_value: oldColumn.default_value
          }]
        };
        await updateColumnService(route.params.databaseId, route.params.tableId, requestPayload);
        /** 
         * the delay here is to complete the table alter command because alter locks the table for a while for external storage data to inline data type or vice versa.
         * In this lock window if we perform the read operation it will return an empty set of data.
         * */
        await delay(500);
        await fetchTableStructure();
        buefy.toast.open({
          duration: 5000,
          message: 'Column updated.',
          position: 'is-top',
          type: 'is-success'
        });
      }
    } catch (err) {
      console.error(err);
      buefy.snackbar.open({
        message: 'Failed to update column',
        type: 'is-danger',
        position: 'is-top',
        indefinite: false
      });
    }
  };
  /**
   * @param {string} colName 
   */
  const toggleColumnActionDropdown = colName => {
    // @VUE3: use functional template ref in vue 3 instead (check offical vue 3 docs for info), vue 2 composition api plugin doesn't support them 
    vm.proxy.$refs[`columnActionDropdown${colName}`].toggle();
  };
  /**
   * @param {string} colName
   * @param {number} index
   */
  const confirmColumnDeleting = (colName, index) => {
    toggleColumnActionDropdown(colName);
    buefy.dialog.confirm({
      title: 'Deleting column',
      message: 'Are you sure you want to <b>delete</b> this column? This action cannot be undone.',
      confirmText: 'Delete column',
      type: 'is-danger',
      hasIcon: true,
      onConfirm: () => {
        removeColumn(colName, index);
      }
    });
  };
  /**
   * @param {string} colName
   * @param {number} index
   */
  const removeColumn = async (colName, index) => {
    try {
      await deleteColumnService(route.params.databaseId, route.params.tableId, colName);
      table.columns.splice(index, 1);
      buefy.toast.open('Column deleted!');
    } catch (err) {
      console.error(err);
    }
  };
  const goToData = () => {
    router.push(`/application/${route.params.appId}/database/${route.params.databaseId}/table/${route.params.tableId}/data`);
  };
  const draggingRowIndex = ref(false);
  const rowDragStart = payload => {
    draggingRowIndex.value = payload.index;
  };
  const dragover = payload => {
    payload.event.preventDefault();
  };
  const drop = async payload => {
    const startIdx = draggingRowIndex.value;
    const endIdx = payload.index;
    let tableColumns = cloneDeep(table.columns);
    const [removeElm] = tableColumns.splice(startIdx, 1);
    tableColumns.splice(endIdx, 0, removeElm);
    tableColumns = tableColumns.map((elm, idx) => {
      elm.column_position = idx;
      return elm;
    });
    await updateColumnPositionService(route.params.databaseId, route.params.tableId, tableColumns);
    table.columns = tableColumns;
  };
  return {
    isDefaultDateTypeColumn,
    table,
    isColumnModalActive,
    isColumnEdited,
    options,
    selectedColumn,
    handleColumnTableClick,
    openColumnModal,
    isAdmin,
    handleColumnUpdate,
    toggleColumnActionDropdown,
    confirmColumnDeleting,
    goToData,
    rowDragStart,
    dragover,
    drop
  };
};
__sfc_main.components = Object.assign({
  BaseModal,
  CardPopup
}, __sfc_main.components);
export default __sfc_main;
</script>

<style lang="scss">
@import '~@/style/variables.scss';
@import "~@/style/components.scss";

.columns-table {
  tbody {
    tr {
      &:first-child {
        cursor: default;
        &:hover {
          background: rgba($grey-4, 0.1);
        }
      }
    }
  }
}

.has-light-bg {
  background: $grey-5 !important;
}

.has-insert-shadow {
  box-shadow: -10px 9px 15px -6px rgba(0, 0, 0, 0.1);
}
</style>